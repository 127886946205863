import { observable } from "mobx";
import { injectable } from "react-inversify";
import { IStore } from "./IStore";

@injectable()
export abstract class BaseStore implements IStore {
  @observable
  public loading: boolean = false;

  @observable
  public hasError: boolean = false;

  @observable
  public error?: string;

  @observable
  public paused?: boolean;
}

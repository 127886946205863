import React from 'react';
import "./App.css";
import { Provider } from "react-inversify";
import { container, changeNotification } from './ioc';
import { AppRoute } from './AppRoute';

class App extends React.Component<{}, {}> {

  public render() {
      return <Provider container={container} changeNotification={changeNotification}>
              <div className="App">
                <AppRoute/>
              </div>
            </Provider>;
  }
}

export default App;
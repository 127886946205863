import {
  HubConnectionBuilder,
  HubConnection,
  LogLevel
} from "@microsoft/signalr";
import { injectable, inject } from "react-inversify";

@injectable()
export class LiveStreamService {
  private connection?: HubConnection | any;

  connect(): Promise<void> {
    this.connection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_WITH_URL || "")
      .configureLogging(LogLevel.Information)
      .build();

    return this.connection
      .start()
      .then(() => {
        console.log("live stream connection was established");
      })
      .catch((err: any) => {
        console.log("connection error");
      });
  }

  iceServers = () => {
    return this.connection!.send("iceServers");
  };

  request = (deviceId: string) => {
    return this.connection!.send("request", deviceId);
  };

  addIce = (deviceId: string, iceCandidate: RTCIceCandidate) => {
    return this.connection!.send(
      "ice",
      deviceId,
      iceCandidate.sdpMid,
      iceCandidate.sdpMLineIndex,
      iceCandidate.candidate
    );
  };

  addSdp = (deviceId: string, sdp: string) => {
    return this.connection!.send("sdp", deviceId, sdp);
  };

  registerServersCallback = (callback: (servers: any) => void) => {
    this.connection!.on("servers", function (servers: any) {
      console.log(servers);
      callback(servers);
    });
  };

  registerIceCallback = (callback: (ice: any) => void) => {
    this.connection!.on("ice", function (ice: any) {
      console.log(ice);
      callback(ice);
    });
  };

  registerSdpCallback = (callback: (sdp: any) => void) => {
    this.connection!.on("sdp", function (sdp: any) {
      callback(sdp);
    });
  };
}

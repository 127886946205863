import { Container, ChangeNotification } from 'react-inversify';
import { LiveStreamService } from './services/LiveStreamService';
import { LiveStreamStore } from './stores/LiveStreamStore';

const containerInstance = new Container();

containerInstance.bind(LiveStreamService).toSelf();

containerInstance.bind(LiveStreamStore).toSelf();

export const container = containerInstance;
export const changeNotification = new ChangeNotification();

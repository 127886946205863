import React from 'react';
import "./App.css";
import compose from './helpers/compose';
import { Switch, Route } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { LiveStream } from './areas/live-stream/LiveStream';

interface AppProps {
}

class AppRouteComponent extends React.Component<AppProps, {}> {

  public render() {

    return (
      <Router>
        <Switch>
          <Route path="/live-stream/:deviceId" exact={true}>
            <LiveStream />
          </Route>          
        </Switch>
      </Router>
    );
  }
}

export const AppRoute = compose(AppRouteComponent);
import React, { ChangeEvent, Component } from "react";
import ProTypes from "prop-types";
import { customButtons } from "./constants";
import { Axis, Button } from "react-gamepad";
import GamepadEx from "./gamepad";

interface GamepadJoystickProps {
  onStateUpdated: (value: ArrayBuffer) => void;
}

class GamepadJoystickState {
  buttons: BigUint64Array = new BigUint64Array(1);
  l2State: Int8Array = new Int8Array(1);
  r2State: Int8Array = new Int8Array(1);
  leftX: Int16Array = new Int16Array(2);
  leftY: Int16Array = new Int16Array(2);
  rightX: Int16Array = new Int16Array(2);
  rightY: Int16Array = new Int16Array(2);
  prevState?: string;
  schema: string = "ds4";
}

class GamepadJoystick extends Component<
  GamepadJoystickProps,
  GamepadJoystickState
> {
  static propTypes = {
    keys: ProTypes.object,
    interval: ProTypes.number,
    onKeyPress: ProTypes.func,
    onKeyRelease: ProTypes.func
  };

  scaleSize: number = 0.7;

  state = {
    buttons: new BigUint64Array(1),
    l2State: new Int8Array(1),
    r2State: new Int8Array(1),
    leftX: new Int16Array(1),
    leftY: new Int16Array(1),
    rightX: new Int16Array(1),
    rightY: new Int16Array(1),
    schema: "ds4"
  } as GamepadJoystickState;

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown.bind(this));
    document.addEventListener("keyup", this.handleKeyUp.bind(this));
  }

  handleMouseDown(e: any) {
    let name = e.target.dataset.name;
    this.mouseHandler(name, 1);
  }

  handleMouseUp(e: any) {
    let name = e.target.dataset.name;
    this.mouseHandler(name, 0);
  }

  mouseHandler(name: string, down: number) {
    console.log(`Mouse down ${down} on button ${name}`);
    let foundKey = null;
    for (let key in customButtons) {
      if ((customButtons as any)[key] == name) {
        foundKey = key;
        break;
      }
    }

    if (name === "stick-1") {
      this.state.l2State[0] = down;
      this.updateState();
    }
    if (name === "stick-2") {
      this.state.r2State[0] = down;
      this.updateState();
    }

    if (foundKey !== null) {
      console.log(`The button ${name} was pressed`);
      let buttons = this.state.buttons[0];
      if (down) {
        buttons |= BigInt(1 << parseInt(foundKey));
      } else {
        buttons &= BigInt(~(1 << parseInt(foundKey)));
      }
      this.state.buttons[0] = buttons;
      this.updateState();
    } else {
      console.log(`The key ${name} was not found`);
    }
  }

  handleKeyDown(e: any) {
    this.handleKeyStateChange(e, true);
  }

  handleKeyUp(e: any) {
    this.handleKeyStateChange(e, false);
  }

  handleKeyStateChange(e: any, down: boolean) {
    if (e.target?.id === "textfield") return;

    let num = "";

    if (e.code == "Space") num = "14";
    else if (e.code == "KeyL" && e.shiftKey) num = "8";
    else if (e.code == "KeyL" && e.ctrlKey) num = "10";
    else if (e.code == "KeyR" && e.shiftKey) num = "9";
    else if (e.code == "KeyR" && e.ctrlKey) num = "11";
    else if (e.code == "KeyO") num = "12";
    // Options
    else if (e.code == "KeyP") num = "13";
    // Share
    else if (e.code == "KeyH") num = "15";
    // Home
    else if (e.code == "KeyW") num = "0";
    else if (e.code == "KeyD") num = "1";
    else if (e.code == "KeyA") num = "2";
    else if (e.code == "KeyS") num = "3";
    else if (e.code == "ArrowLeft") num = "4";
    else if (e.code == "ArrowRight") num = "5";
    else if (e.code == "ArrowUp") num = "6";
    else if (e.code == "ArrowDown") num = "7";

    if (num !== "") e.preventDefault();

    let buttons = this.state.buttons[0];
    if (down) {
      buttons |= BigInt(1 << parseInt(num));
    } else {
      buttons &= BigInt(~(1 << parseInt(num)));
    }
    this.state.buttons[0] = buttons;
    this.updateState();
  }

  updateState() {
    this.setState(this.state);
    let outArr = new Uint8Array(14);
    outArr.set(
      new Uint8Array(
        this.state.buttons.buffer,
        this.state.buttons.byteOffset,
        this.state.buttons.byteLength
      )
    );

    outArr.set(this.state.l2State, 4);
    outArr.set(this.state.r2State, 5);
    outArr.set(
      new Uint8Array(
        this.state.leftX.buffer,
        this.state.leftX.byteOffset,
        this.state.leftX.byteLength
      ),
      6
    );
    outArr.set(
      new Uint8Array(
        this.state.leftY.buffer,
        this.state.leftY.byteOffset,
        this.state.leftY.byteLength
      ),
      8
    );
    outArr.set(
      new Uint8Array(
        this.state.rightX.buffer,
        this.state.rightX.byteOffset,
        this.state.rightX.byteLength
      ),
      10
    );
    outArr.set(
      new Uint8Array(
        this.state.rightY.buffer,
        this.state.rightY.byteOffset,
        this.state.rightY.byteLength
      ),
      12
    );

    let out = btoa(String.fromCharCode(...Array.from(outArr)));
    this.setState({ prevState: out });
    if (this.props.onStateUpdated) {
      this.props.onStateUpdated(outArr.buffer);
    }
  }

  connectHandler(gamepadIndex: number) {
    console.log(`Gamepad ${gamepadIndex} connected !`);
  }

  disconnectHandler(gamepadIndex: number) {
    console.log(`Gamepad ${gamepadIndex} disconnected !`);
  }

  buttonChangeHandler(buttonName: Button | "Home", down: boolean) {
    if (down) {
      console.log(buttonName, down);
    }
    let num = "";

    if (buttonName == "A") num = "0";
    else if (buttonName == "B") num = "1";
    else if (buttonName == "X") num = "2";
    else if (buttonName == "Y") num = "3";
    else if (buttonName == "DPadLeft") num = "4";
    else if (buttonName == "DPadRight") num = "5";
    else if (buttonName == "DPadUp") num = "6";
    else if (buttonName == "DPadDown") num = "7";
    else if (buttonName == "LB") num = "8";
    else if (buttonName == "RB") num = "9";
    else if (buttonName == "LT") num = "10";
    else if (buttonName == "RT") num = "11";
    else if (buttonName == "Back") num = "12";
    else if (buttonName == "Start") num = "13";
    else if (buttonName == "Home") num = "15";
    else if (buttonName == "LS") this.state.l2State[0] = down ? 1 : 0;
    else if (buttonName == "RS") this.state.r2State[0] = down ? 1 : 0;

    if (num !== "") {
      let buttons = this.state.buttons[0];
      if (down) {
        buttons |= BigInt(1 << parseInt(num));
      } else {
        buttons &= BigInt(~(1 << parseInt(num)));
      }
      this.state.buttons[0] = buttons;
    }

    this.updateState();
  }

  axisChangeHandler(axisName: Axis, value: number, previousValue: any) {
    console.log(axisName, value);
    if (axisName == "LeftStickX") {
      this.state.leftX = new Int16Array([32767 * value]);
    } else if (axisName == "LeftStickY") {
      this.state.leftY = new Int16Array([32767 * -value]);
    }
    if (axisName == "RightStickX") {
      this.state.rightX = new Int16Array([32767 * value]);
    } else if (axisName == "RightStickY") {
      this.state.rightY = new Int16Array([32767 * -value]);
    }
    this.updateState();
  }

  handleSchemaChange(e: ChangeEvent<HTMLSelectElement>) {
    this.state.schema = e.target.value;
    this.setState(this.state);
  }

  render() {
    if (window.screen.width < window.screen.height) {
      this.scaleSize = 1;
    }
    const {
      buttons,
      leftX,
      leftY,
      rightX,
      rightY,
      l2State,
      r2State
    } = this.state;
    return (
      <>
        <GamepadEx
          onConnect={this.connectHandler.bind(this)}
          onDisconnect={this.disconnectHandler.bind(this)}
          onButtonChange={this.buttonChangeHandler.bind(this)}
          onAxisChange={this.axisChangeHandler.bind(this)}
        >
          <div
            className="container"
            id="gamepads"
            style={{ width: "980px", overflow: "hidden", height: 450 }}
          >
            <div
              className="console_select"
              style={{ left: "0px", position: "relative" }}
            >
              <select
                className="console ds4"
                id="cselect"
                value={this.state.schema}
                onChange={this.handleSchemaChange.bind(this)}
              >
                <option value="ds4">DualShock 4 Black</option>
                <option value="ds4 white">DualShock 4 White</option>
              </select>
            </div>
            <div
              className={`controller active ${this.state.schema} half`}
              id="gamepad-0"
              onMouseDown={this.handleMouseDown.bind(this)}
              onTouchStart={this.handleMouseDown.bind(this)}
              onMouseUp={this.handleMouseUp.bind(this)}
              onTouchEnd={this.handleMouseUp.bind(this)}
              style={{
                transform: `scale(${this.scaleSize}) translate(-50%,-50%)`,
                transformOrigin: "0 0",
                opacity: 0.8
              }}
            >
              <div className="triggers">
                <span
                  className={`trigger left ${
                    buttons[0] & BigInt(1 << 10) ? "pressed" : ""
                  }`}
                  data-name="l3"
                ></span>
                <span
                  className={`trigger right ${
                    buttons[0] & BigInt(1 << 11) ? "pressed" : ""
                  }`}
                  data-name="r3"
                ></span>
                <span
                  className={`trigger-button left ${
                    buttons[0] & BigInt(1 << 10) ? "pressed" : ""
                  }`}
                  data-name="l3"
                ></span>
                <span
                  className={`trigger-button right ${
                    buttons[0] & BigInt(1 << 11) ? "pressed" : ""
                  }`}
                  data-name="r3"
                ></span>
                <span className="clear"></span>
              </div>
              <div className="bumpers">
                <span
                  className={`bumper left ${
                    buttons[0] & BigInt(1 << 8) ? "pressed" : ""
                  }`}
                  data-name="l1"
                ></span>
                <span
                  className={`bumper right ${
                    buttons[0] & BigInt(1 << 9) ? "pressed" : ""
                  }`}
                  data-name="r1"
                ></span>
                <span className="clear"></span>
              </div>
              <div
                className={`touchpad ${
                  buttons[0] & BigInt(1 << 14) ? "pressed" : ""
                }`}
                data-name="touch"
              ></div>
              <div className="quadrant"></div>
              <div
                className={`meta ${
                  buttons[0] & BigInt(1 << 15) ? "pressed" : ""
                }`}
                data-name="home"
              ></div>
              <div className="arrows">
                <span
                  className={`back ${
                    buttons[0] & BigInt(1 << 12) ? "pressed" : ""
                  }`}
                  data-name="options"
                ></span>
                <span
                  className={`start ${
                    buttons[0] & BigInt(1 << 13) ? "pressed" : ""
                  }`}
                  data-name="share"
                ></span>
                <span className="clear"></span>
              </div>
              <div className="abxy">
                <span
                  className={`button a cross ${
                    buttons[0] & BigInt(1) ? "pressed" : ""
                  }`}
                  data-name="cross"
                ></span>
                <span
                  className={`button b circle ${
                    buttons[0] & BigInt(1 << 1) ? "pressed" : ""
                  }`}
                  data-name="circle"
                ></span>
                <span
                  className={`button x square ${
                    buttons[0] & BigInt(1 << 2) ? "pressed" : ""
                  }`}
                  data-name="square"
                ></span>
                <span
                  className={`button y triangle ${
                    buttons[0] & BigInt(1 << 3) ? "pressed" : ""
                  }`}
                  data-name="triangle"
                ></span>
              </div>
              <div className="sticks">
                <span
                  className={`stick left ${
                    l2State[0] || leftX[0] || leftY[0] ? "pressed" : ""
                  }`}
                  data-name="stick-1"
                ></span>
                <span
                  className={`stick right ${
                    r2State[0] || rightX[0] || rightY[0] ? "pressed" : ""
                  }`}
                  data-name="stick-2"
                ></span>
              </div>
              <div className="wheels">
                <span
                  className={`wheel left ${
                    l2State[0] || leftX[0] || leftY[0] ? "pressed" : ""
                  }`}
                  data-name="stick-1-wheel"
                ></span>
                <span
                  className={`wheel right ${
                    r2State[0] || rightY[0] || rightX[0] ? "pressed" : ""
                  }`}
                  data-name="stick-2-wheel"
                ></span>
              </div>
              <div className="dpad">
                <span
                  className={`face up ${
                    buttons[0] & BigInt(1 << 6) ? "pressed" : ""
                  }`}
                  data-name="up"
                ></span>
                <span
                  className={`face down ${
                    buttons[0] & BigInt(1 << 7) ? "pressed" : ""
                  }`}
                  data-name="down"
                ></span>
                <span
                  className={`face left ${
                    buttons[0] & BigInt(1 << 4) ? "pressed" : ""
                  }`}
                  data-name="left"
                ></span>
                <span
                  className={`face right ${
                    buttons[0] & BigInt(1 << 5) ? "pressed" : ""
                  }`}
                  data-name="right"
                ></span>
              </div>
              <div className="fstick" data-name="arcade-stick"></div>
            </div>
          </div>
        </GamepadEx>
      </>
    );
  }
}

export default GamepadJoystick;

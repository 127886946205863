import React, { ChangeEvent, PureComponent } from "react";
import Button from "@material-ui/core/Button";
import PauseIcon from "@material-ui/icons/Pause";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Tooltip from "@material-ui/core/Tooltip";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";

interface IAction {
  type: string;
  payload: string;
}
interface IBottomInputProps {
  onClick: (action: string, payload?: string) => void;
}

class BottomInput extends PureComponent<IBottomInputProps> {
  state = { textInput: "" };

  keyPressHandler = (e: TextFieldProps) => {
    if (e.key === "Enter") this.props.onClick("kb", this.state.textInput);
  };

  textChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    this.setState({ textInput: value });
  };

  render() {
    const { onClick } = this.props;
    const { textInput } = this.state;
    return (
      <div className="bottom-input-block">
        <TextField
          value={textInput}
          onChange={this.textChangeHandler}
          onKeyPress={this.keyPressHandler}
          variant="outlined"
          size="small"
          id="textfield"
        />
        <Button variant="contained" onClick={() => onClick("kb", textInput)}>
          OK
        </Button>
      </div>
    );
  }
}

export default BottomInput;

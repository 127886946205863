import React, { PureComponent } from "react";
import Button from "@material-ui/core/Button";
import PauseIcon from "@material-ui/icons/Pause";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Tooltip from "@material-ui/core/Tooltip";

interface IAction {
  type: string;
  payload: string;
}
interface IRightMenuProps {
  onClick: (action: string) => void;
  paused?: boolean;
}

class RightMenu extends PureComponent<IRightMenuProps> {
  render() {
    const { paused, onClick } = this.props;
    return (
      <div className="right-menu-buttons">
        {paused ? <div className="sub-label">Paused...</div> : null}
        <Tooltip title="Pause Console" placement="top">
          <Button
            variant="contained"
            className={paused ? "paused" : ""}
            onClick={() => onClick("pause")}
          >
            <PauseIcon />
          </Button>
        </Tooltip>

        <Tooltip title="Get Log" placement="top">
          <Button variant="contained" onClick={() => onClick("log")}>
            <ListAltIcon />
          </Button>
        </Tooltip>

        <Tooltip title="Screenshot" placement="top">
          <Button variant="contained" onClick={() => onClick("screenshot")}>
            <PhotoCamera />
          </Button>
        </Tooltip>
      </div>
    );
  }
}

export default RightMenu;

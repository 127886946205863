import React from "react";
import compose from "../../helpers/compose";
import { observer } from "mobx-react";
import { connect } from "react-inversify";
import Container from "@material-ui/core/Container";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { RouterProps } from "../../common/RouterProps";
import { WithStyles, withStyles, Typography, Tooltip } from "@material-ui/core";
import { withRouter } from "react-router";
import { styles } from "../../common/Styles";
import { LiveStreamStore } from "../../stores/LiveStreamStore";
import GamepadJoystick from "../../components/gamepad";
import RightMenu from "../../components/rightMenu";
import BottomInput from "../../components/bottomInput/index";

interface LiveStreamProps {
  store: LiveStreamStore;
}

@observer
class LiveStreamComponent extends React.Component<
  LiveStreamProps & RouterProps & WithStyles<typeof styles>
> {
  componentDidMount() {
    this.props.store.get(this.props.match.params.deviceId);
  }

  handleGamepadStateUpdated(value: ArrayBuffer) {
    const buffer = Buffer.from(value);
    const base64String = buffer.toString("base64");
    this.props.store.command({ type: "gamepad", payload: base64String });
  }

  pause = () => {
    this.props.store.command({ type: "pause", payload: "" });
  };

  onClick = (type: String, payload: String = "") => {
    this.props.store.command({ type, payload });
  };

  render() {
    const { classes, store } = this.props;
    const { loading, deviceId, paused } = store;

    return (
      <>
        <div className="live-stream-video" onClick={() => this.pause()}>
          <Card square={true}>
            <CardHeader
              title={`Live stream :: ${deviceId}`}
              className="card-header"
            ></CardHeader>
            <CardContent style={{ padding: 0, height: 480 }}>
              <video
                id="video"
                autoPlay={true}
                width="960"
                height="480"
                style={{ background: "#000" }}
                playsInline={true}
                muted={true}
              ></video>
            </CardContent>
          </Card>
        </div>
        <div className="live-stream-joystick">
          <GamepadJoystick
            onStateUpdated={this.handleGamepadStateUpdated.bind(this)}
          />
          <RightMenu onClick={this.onClick} paused={paused} />
        </div>
        <BottomInput onClick={this.onClick} />
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

export const LiveStream = compose(
  withRouter,
  withStyles(styles),
  connect(
    LiveStreamStore,
    (deps: LiveStreamStore, ownProps: LiveStreamProps) => {
      return {
        store: deps
      } as LiveStreamProps;
    }
  )
)(LiveStreamComponent);

export const customButtons = {
    0: 'cross',
    1: 'circle',
    2: 'square',
    3: 'triangle',
    4: 'left',
    5: 'right',
    6: 'up',
    7: 'down',
    8: 'l1',
    9: 'r1',
    10: 'l3',//left shoulder top
    11: 'r3',//right shoulder top
    12: 'options',
    13: 'share',
    14: 'touch',
    15: 'home'
}
import { action, observable, runInAction } from "mobx";
import { inject, injectable } from "react-inversify";
import { LiveStreamService } from "../services/LiveStreamService";
import { BaseStore } from "./base/BaseStore";
import FileSaver from "file-saver";
import { dataURItoBlob, copyToClipboard } from "./helpers";
// https://localhost:5001/live-stream/Shpilim1991_XBSS

@injectable()
export class LiveStreamStore extends BaseStore {
  @inject(LiveStreamService)
  private liveStreamService!: LiveStreamService;

  @observable
  public deviceId?: string;

  public localStream?: any;

  public peerConnection?: RTCPeerConnection;

  public iceCandidates: Array<RTCIceCandidate> = [];

  public remoteIceCandidates: Array<RTCIceCandidate> = [];

  public remoteStream?: MediaStream;

  public dataChannel?: RTCDataChannel;

  public isRemoteSdpSet: boolean = false;

  public iceServers: any;

  @action.bound
  get(deviceId: string) {
    this.loading = true;
    this.paused = false;

    try {
      this.deviceId = deviceId;

      // initialize empty video stream
      this.remoteStream = new MediaStream();

      // Assign remoe video stream to html video player.
      const video: any = document.querySelector("#video");
      // Older browsers may not have srcObject
      if ("srcObject" in video) {
        try {
          video.srcObject = this.remoteStream;
        } catch (err) {
          if (err && err.name != "TypeError") {
            throw err;
          }
          // Even if they do, they may only support MediaStream
          video.src = URL.createObjectURL(this.remoteStream);
        }
      } else {
        video.src = URL.createObjectURL(this.remoteStream);
      }

      this.startStream();
    } catch (err) {
      console.error(err);
    }
  }

  @action.bound
  startStream() {
    // connect to webSocket.
    this.liveStreamService.connect().then(() => {
      this.liveStreamService.registerServersCallback((servers: any) => {
        for (var server of servers) {
          server.username = server.turnUserName;
          server.credential = server.turnPassword;
        }

        this.iceServers = servers;
        this.initializePeerConnection({ iceServers: this.iceServers });

        // Request stream from remote device.
        this.liveStreamService.request(this.deviceId!).then(() => {
          // Remote device ice candidate listener.
          this.liveStreamService.registerIceCallback((message: any) => {
            console.log(`New remote ice arrived`);

            if (this.isRemoteSdpSet) {
              this.peerConnection!.addIceCandidate({
                sdpMid: message.sdpMid,
                sdpMLineIndex: message.sdpMLineIndex,
                candidate: message.content
              });
            } else {
              this.remoteIceCandidates.push(
                new RTCIceCandidate({
                  sdpMid: message.sdpMid,
                  sdpMLineIndex: message.sdpMLineIndex,
                  candidate: message.content
                })
              );
            }
          });

          // Remote device sdp listener.
          this.liveStreamService.registerSdpCallback((message: any) => {
            // Set remote sdp.
            this.peerConnection!.setRemoteDescription(
              new RTCSessionDescription({
                type: "offer",
                sdp: message.content
              })
            ).then(() => {
              // Create answer on remote offer.
              this.peerConnection!.createAnswer().then(sdp => {
                // Set local sdp.
                this.peerConnection!.setLocalDescription(sdp).then(() => {
                  // Send local sdp to the remote device.
                  this.liveStreamService
                    .addSdp(this.deviceId!, sdp!.sdp!)
                    .then(() => {
                      this.isRemoteSdpSet = true;

                      for (var candidate of this.remoteIceCandidates) {
                        this.peerConnection!.addIceCandidate(candidate);
                      }

                      for (var candidate of this.iceCandidates) {
                        this.liveStreamService
                          .addIce(this.deviceId!, candidate)
                          .then(() => {
                            console.log(
                              "Ice canidate was pushed to remote peer"
                            );
                          });
                      }
                    });
                });
              });
            });
          });
        });
      });

      this.liveStreamService.iceServers().then(() => {
        console.log("Ice servers requested");
      });
    });
  }

  initializePeerConnection(iceServers: any) {
    this.peerConnection = new RTCPeerConnection(iceServers);

    this.peerConnection!.onicecandidate = ev => {
      console.log("New local ice candidate");
      if (ev.candidate != null) {
        if (this.isRemoteSdpSet) {
          console.log("Push ice canidate to remote peer");
          this.liveStreamService
            .addIce(this.deviceId!, ev.candidate)
            .then(() => {
              console.log("Ice canidate was pushed to remote peer");
            });
        } else {
          this.iceCandidates.push(ev.candidate);
        }
      }
    };

    this.peerConnection!.onicegatheringstatechange = ev => {
      console.log(
        `Ice candidates gathering state change: ${
          this.peerConnection!.iceGatheringState
        }`
      );
      if (this.peerConnection!.iceGatheringState == "complete") {
        runInAction(() => {
          this.loading = false;
        });
        console.log("Gathering completed");
      }
    };

    this.peerConnection!.ondatachannel = ev => {
      this.dataChannel = ev.channel;
      this.dataChannel.onmessage = msg => {
        const json = Buffer.from(msg.data, "base64").toString();
        const file = JSON.parse(json);

        if (file.type === "screenshot") {
          const fileName = `rc_screenshot_${Date.now()}`;
          const pngBlob = dataURItoBlob(
            `data:image/png;base64,${file.payload}`
          );
          FileSaver.saveAs(pngBlob, fileName);
          copyToClipboard(pngBlob);
        }

        if (file.type === "log") {
          const fileName = `rc_log_${Date.now()}.txt`;
          FileSaver.saveAs(
            dataURItoBlob(`data:text/plain;base64,${file.payload}`),
            fileName
          );
        }

        if (file.type === "pause") {
          this.paused = file.payload === "True";
        }
      };
    };

    this.peerConnection!.onicecandidateerror = ev => {
      console.error("ice candidate error:", ev);
    };

    this.peerConnection!.onconnectionstatechange = () => {
      console.log(
        `Connection state changed ${this.peerConnection!.connectionState}`
      );
      if (this.peerConnection!.connectionState === "connected") {
        runInAction(() => {
          this.loading = false;
        });
      } else if (this.peerConnection!.connectionState === "failed") {
        alert("Connection failed");
        runInAction(() => {
          this.loading = false;
        });
      }
    };

    this.peerConnection!.ontrack = e => {
      console.log(`New track was added`);
      this.remoteStream!.addTrack(e.track);
    };
  }

  command(value: any) {
    console.log("command: ", value);
    if (this.dataChannel) {
      this.dataChannel.send(JSON.stringify(value));
    }
  }
}
